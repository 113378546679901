import { ProductSimilarity } from './ig-convos.model';

export type ProductBase = {
    allow_installments: boolean;
    attachment_url: string;
    attachment_format?: string;
    attachment_name?: string;
    categories: CategoriesId;
    content_url?: string;
    ecommerce_id: number;
    format_type: ProductFormatType;
    image: string;
    name: string;
    payment_type?: PaymentType;
    price: number;
    product_id: number;
    removed: boolean;
    sku: string;
    status: string;
    group_id: number;
    stock_status: StockStatus;
    stock_qty: number;
    url: string;
    visual_id: string;
    qty_ordered: number;
    qty_ordered_with_discount: number;
    refund_rate: number;
    revenues: number;
    description: string;
    min_first_installment_percentage: number;
    tax_id: string;
};

export type ParentProduct = ProductBase & {
    type: (typeof PROD_TYPE)['PRIMARY'];
};
export type VariantProduct = ProductBase & {
    type: (typeof PROD_TYPE)['VARIANT'];
    option1: string;
    option2: string;
    parent_product_id: number;
};
export type ParentOrVariantProduct = ParentProduct | VariantProduct;

export type RelatedProducts = {
    similarities?: ProductSimilarity[];
    bundle?: VariantProduct[];
};
export type ProdWithRelatedProds = VariantProduct & RelatedProducts;

export const PROD_TYPE = {
    PRIMARY: 'primary',
    VARIANT: 'variant',
} as const;
export type ProductType = (typeof PROD_TYPE)[keyof typeof PROD_TYPE];

export const PROD_STATUS = {
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED',
} as const;
export type ProductStatus = (typeof PROD_STATUS)[keyof typeof PROD_STATUS];

export const STOCK_STATUS = {
    IN_STOCK: 'IN_STOCK',
    REORDER_SOON: 'REORDER_SOON',
    REORDER_NOW: 'REORDER_NOW',
    OVERSTOCK: 'OVERSTOCK',
} as const;
export type StockStatus = (typeof STOCK_STATUS)[keyof typeof STOCK_STATUS];

export type GetProductsParams = {
    q?: string;
    option1?: string;
    option2?: string;
    type?: ProductType;
    stock_qty?: number;
    status?: ProductStatus;
    parent_product_id?: number;
    eligible?: string;
    limit?: number;
    offset?: number;
    sort?: string;
    removed?: boolean;
};

export type AddToCartPload = {
    product_id: number;
    quantity: number;
};
export const PAYMENT_TYPE = {
    single: 'SINGLE',
    subscription: 'SUBSCRIPTION',
} as const;
export type PaymentType = (typeof PAYMENT_TYPE)[keyof typeof PAYMENT_TYPE];
export const isPaymentType = (obj: string): obj is PaymentType =>
    Object.values(PAYMENT_TYPE).includes(obj as any);
export const PRODUCT_FORMAT_TYPE = {
    digital: 'DIGITAL',
    physical: 'PHYSICAL',
} as const;
export type ProductFormatType = (typeof PRODUCT_FORMAT_TYPE)[keyof typeof PRODUCT_FORMAT_TYPE];
export type CategoriesId = {
    category_id: string;
    category_name?: string;
}[];
export interface ProductNewDTO {
    product: ProductDataNewDTO;

    /**
     * Attachment file to save in database, now only accept pdf
     */
    attachment?: File;
    /**
     * Image to upload in our server and save the url
     */
    image?: File;
    /**
     * Product content of DIGITAL products
     */
    product_file?: File;
}
export interface ProductDataNewDTO {
    sku: string;
    name: string;
    /**
     * Product image URL
     * It takes priority over “image” request parameter
     */
    image?: string | null;
    /**
     * Product site URL
     */
    url?: string;
    type?: ProductType;
    option1?: string;
    option2?: string;

    stock_qty?: number;
    price: number;
    /**
     * Product file url.
     * It takes priority over “product_file” request parameter
     */
    content_url?: string | null;
    format_type: ProductFormatType;
    payment_type?: PaymentType;
    allow_installments?: boolean;
    /**
     * Only allowed if ‘allow_installments’ is true
     */
    min_first_installment_percentage?: number;
    tax_id?: string;
    categories?: CategoriesId;
    description?: string;

    attachment_url?: string;
}
